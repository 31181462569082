import { action, computed, flow, makeObservable, observable } from 'mobx';
import { isHydrated, isPersisting, makePersistable, stopPersisting } from 'mobx-persist-store';
import { unionWith } from 'lodash';
import { Client } from 'urql';

import CSRF from '@/lib/Csrf';
import { extractItems } from '@/lib/GraphQLHelper';
import Log from '@/lib/Log';
import stockQuery from '@/lib/Stock/stock.graphql';
import { localStorage, localStorageSupported } from '@/lib/Storage';

export default class ShortlistStore {
  static shortlistLimit = 99;

  loaded = false;
  shortlist: StockItem[] = [];
  shortlistID?: string = undefined;
  undoBackup: StockItem[] = [];

  sharedShortlist: StockItem[] = [];
  sharedShortlistID?: string = undefined;

  popularModels: string[] = [];

  constructor() {
    makeObservable(this, {
      loaded: observable,
      shortlist: observable,
      shortlistID: observable,
      sharedShortlist: observable,
      sharedShortlistID: observable,
      popularModels: observable,
      size: computed,
      isHydrated: computed,
      isPersisting: computed,
      vehicleLoaded: computed,
      toggle: action,
      clear: action,
      undoClear: action,
      resetShortlistID: action,
      currentShortlistURL: computed,
    });

    if (typeof window !== 'undefined') {
      makePersistable(this, {
        name: 'Shortlist',
        properties: ['shortlist', 'shortlistID'],
        storage: localStorageSupported ? localStorage : undefined,
      });
    }
  }

  stopPersisting = () => {
    stopPersisting(this);
  };

  hasShortlisted = (stockNo: string) => {
    return this.shortlist.filter((item) => item.stockNo === stockNo).length > 0;
  };

  get size() {
    return this.shortlist.length;
  }

  get isHydrated(): boolean {
    return isHydrated(this);
  }

  get isPersisting(): boolean {
    return isPersisting(this);
  }

  get vehicleLoaded() {
    return this.loaded;
  }

  toggle = (vehicle: StockItem) => {
    if (!this.hasShortlisted(vehicle.stockNo)) {
      if (this.shortlist.length < ShortlistStore.shortlistLimit) {
        this.shorlistSet(vehicle);
      }
    } else {
      this.shorlistDelete(vehicle.stockNo);
    }

    this.resetShortlistID();
  };

  shorlistSet = (vehicle: StockItem) => {
    this.shortlist.push(vehicle);
  };

  shorlistDelete = (stockNo: string) => {
    const newList = this.shortlist.filter((item: StockItem) => {
      return item.stockNo !== stockNo;
    });
    this.shortlist = newList;
  };

  clear = () => {
    this.undoBackup = [...this.shortlist];
    this.shortlist = [];
    this.resetShortlistID();
  };

  undoClear = () => {
    this.shortlist = [...this.undoBackup];
    this.resetShortlistID();
  };

  resetShortlistID() {
    this.shortlistID = '';
  }

  loadShortlistVehicles = flow(function* f(this: ShortlistStore, urqlClient: Client) {
    let shortlist = this.sharedShortlist.length ? this.sharedShortlist : this.shortlist;
    /* istanbul ignore else */
    if (shortlist.length) {
      const queryPart = Array.from(shortlist.values())
        .map((vehicle) => `stockNo:${vehicle.stockNo}`)
        .join(',');
      try {
        const { data, error } = yield urqlClient
          .query<StockQuery, StockQueryVariables>(stockQuery, {
            limit: ShortlistStore.shortlistLimit,
            filters: queryPart,
          })
          .toPromise();

        if (error) {
          throw new Error('loadShortlistVehicles: Failed to load stock');
        }

        if (!!data?.stockList?.stock?.length) {
          shortlist = extractItems<StockItem>(data.stockList.stock) ?? [];
          if (this.sharedShortlist.length) {
            this.sharedShortlist = shortlist;
          } else {
            this.shortlist = shortlist;
          }
        }
      } catch (error) {
        Log.exception(error, {});
      }
    }
    this.loaded = true;
  });

  getShortlistURL = flow(function* f(this: ShortlistStore, vehicles: StockItem[], appStateCsrfToken?: string) {
    if (this.shortlistID) {
      return this.shortlistURL(this.shortlistID);
    }

    if (vehicles.length) {
      const csrfToken = appStateCsrfToken || (yield CSRF.getCsrf());

      const body = new FormData();
      body.set('vehicles', JSON.stringify(vehicles));
      body.append('CRAFT_CSRF_TOKEN', csrfToken);

      try {
        const json: { error?: string; id?: string } = yield fetch('/shortlist/share', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'X-CSRF-TOKEN': csrfToken,
          },
          credentials: 'same-origin',
          body,
        }).then((response) => response.json());

        if (json.error) {
          Log.error(`ShortlistStore.getShortlistURL failed: ${json.error}`, { body });
          return false;
        } else if (!json.id) {
          Log.error(`ShortlistStore.getShortlistURL did not return an id. response: ${json}`, { body });
          return false;
        }

        this.shortlistID = json.id;
        return this.shortlistURL(json.id);
      } catch (error) {
        Log.exception(error, {});
      }
    }

    return false;
  });

  shortlistURL = (id: string) => `${window.location.origin}/favourites/${id}`;

  get currentShortlistURL() {
    if (this.sharedShortlistID) {
      return this.shortlistURL(this.sharedShortlistID);
    } else if (this.shortlistID) {
      return this.shortlistURL(this.shortlistID);
    }

    return '';
  }

  loadSharedShortlist = flow(function* f(
    this: ShortlistStore,
    shortlistID: string,
    urqlClient: Client,
    noRedirect = false,
  ) {
    if (this.shortlistID === shortlistID && !noRedirect) {
      window.location.assign('/favourites');
    }

    try {
      const json: StockItem[] = yield fetch(`/shortlist/get?id=${shortlistID}`).then((response) => response.json());

      if (!json.length) {
        Log.error(`ShortlistStore.loadSharedShortlist failed: ${json}`, { shortlistID });
        return;
      }

      this.sharedShortlistID = shortlistID;
      this.sharedShortlist = json;

      yield this.loadShortlistVehicles(urqlClient);
    } catch (error) {
      Log.exception(error, {});
    }
  });

  // Don't really care if this fails
  trackShortlistView = async () => {
    const shortlistID = this.sharedShortlistID || this.shortlistID;
    if (shortlistID) {
      try {
        await fetch(`/shortlist/viewed?id=${shortlistID}`);
      } catch (e) {
        /* ignore */
      }
    }
  };

  // Don't really care if this fails
  trackShortlistShare = async () => {
    const shortlistID = this.sharedShortlistID || this.shortlistID;
    if (shortlistID) {
      try {
        await fetch(`/shortlist/shared?id=${shortlistID}`);
      } catch (e) {
        /* ignore */
      }
    }
  };

  setPopularModels = (popularModels: string[]) => {
    this.popularModels = popularModels;
  };

  addSharedShortlistToShortlist = () => {
    if (!this.sharedShortlist?.length) {
      return;
    }
    this.shortlist = unionWith(this.shortlist, this.sharedShortlist, (a, b) => a.stockNo === b.stockNo);
  };
}
