import { makeAutoObservable } from 'mobx';
import { flow } from 'mobx';
import { MergeWithCustomizer, mergeWith, omit } from 'lodash';
import { Client, OperationResult } from 'urql';

import GraphQLError from '@/errors/GraphQLError';
import globalsQuery from '@/graphql/queries/globals.graphql';
import { extractItems } from '@/lib/GraphQLHelper';
import Log from '@/lib/Log';
import SiteGroupConfig, { SiteGroupRouteConfig } from '@/lib/routes';

const generalDefaults: GlobalSets.General.Defaults = {
  locationLinkField: null,
  showContactInFooter: false,
  globalContactInfo: '',
  logo: null,
  logoDark: null,
  featureFilters: [],
  globalConsentMessage: [],
  email: '',
  tradeInDisclaimer: '',
  referralTermsAndConditions: '',
  favicon: null,
  showContactInHeader: false,
  showChatInHeader: false,
  headerLinksVisible: 3,
  hideValuations: false,
  defaultSortOption: '',
};

const financeSetDefaults: GlobalSets.Finance.Defaults = {
  interestRate: 9.5,
  loanLength: 60,
  comparisonRate: 5,
  financeBalloon: 0,
  baseFees: 0,
  afterTradeInPricesEnabled: false,
  egcPriceDisclaimer: '',
  driveAwayPriceDisclaimer: '',
};

const customizationSetDefaults: GlobalSets.Customization.Defaults = {
  toggle: false,
  font: 'Avenir Next W01',
  optionalVehicleFilters: null,
  vehicleCardShowDealerComments: false,
  vehicleCardShowCtaButton: false,
  vehicleCardHideCondition: false,
  vehicleCardHideIndicativeInterestRate: false,
  helpfulHintIcon: null,
  hideSellMyCarForm: false,
  hideConsignmentForm: false,
  sellMyCarFormFieldsVisibilityToggle: null,
  vehicleHistoryReportContent: null,
  actionButtonStyle: 'button' as ButtonType,
  formsStepHelpfulHintBlocks: null,
  defaultStateOption: null,
  defaultStateOptionOnForm: null,
};

const notFoundSetDefaults: GlobalSets.NotFoundPage.Defaults = {
  notFoundHeader: `<h1>We can't find that page</h1><p>Here are some helpful links instead.</p>`,
  linkField: [],
};

const integrationDefaults: GlobalSets.Integrations.Defaults = {
  impelSpincarOnVdp: false,
  impelSpincarColour: '',
  enableInternalDepositSystem: false,
};

export default class Globals {
  site = 'mymoto';
  siteName = 'MyMoto';
  siteConfig?: SiteGroupRouteConfig = undefined;
  twitter = '';
  facebook = '';
  linkedin = '';
  youtube = '';
  instagram = '';
  openingHours: OpeningTime[] = [];
  phone = '';

  // globalsets
  general: GlobalSets.General.Values = generalDefaults;
  finance: GlobalSets.Finance.Values = financeSetDefaults;
  customization: GlobalSets.Customization.Values = customizationSetDefaults;
  notFoundPage: GlobalSets.NotFoundPage.Values = notFoundSetDefaults;
  integrations: GlobalSets.Integrations.Values = integrationDefaults;
  // vdp: transfer fee list
  transferFeeByStates: TransferFeeByStateFragment[] = [];
  // other
  distinguishedStock: DistinguishedStockIconFragment[] = [];

  constructor(initialProps?: Partial<Globals>) {
    Object.assign(this, initialProps);
    makeAutoObservable(this);
  }

  init = flow(function* (this: Globals, urqlClient: Client, site: string) {
    this.site = site;
    this.siteConfig = SiteGroupConfig.getSiteConfig(site || 'mymoto');
    const { data, error, operation }: OperationResult<GlobalsQuery> = yield urqlClient
      .query<GlobalsQuery>(globalsQuery, {
        site,
      })
      .toPromise();

    if (error) {
      throw new GraphQLError(error, operation);
    }

    if (
      !data?.seo ||
      !data?.general ||
      !data?.finance ||
      !data?.customization ||
      !data?.notFoundPage ||
      !data?.integrations ||
      !data?.vdp
    ) {
      Log.error('Failed to init globals', { data });
    } else {
      // SEO
      if (data.seo.metaSiteVarsContainer) {
        const socials = ['twitter', 'facebook', 'youtube', 'instagram'] satisfies Array<Partial<keyof Globals>>;
        const siteVars = JSON.parse(data.seo?.metaSiteVarsContainer);
        const links = siteVars.sameAsLinks;

        this.siteName = siteVars.siteName;
        socials.forEach((type) => {
          this[type] = links[type]?.url || '';
        });
        this.openingHours = siteVars.identity?.localBusinessOpeningHours?.slice(0, 7);
        this.phone = siteVars.identity?.genericTelephone || '';
      }

      // Global Sets
      if ('__typename' in data.general) {
        this.general = globalSetMerge(this.general, data.general, generalSetCustomizer);
      }
      if ('__typename' in data.finance) {
        this.finance = globalSetMerge(this.finance, data.finance);
      }
      if ('__typename' in data.customization) {
        this.customization = globalSetMerge(this.customization, data.customization, customizationSetCustomizer);
      }
      if ('__typename' in data.notFoundPage) {
        this.notFoundPage = globalSetMerge(this.notFoundPage, data.notFoundPage);
      }
      if ('__typename' in data.integrations) {
        this.integrations = globalSetMerge(this.integrations, data.integrations);
      }

      // transfer fee by states
      if ('__typename' in data.vdp) {
        this.transferFeeByStates =
          data.vdp.transferFeeByState?.filter(
            (item): item is TransferFeeByStateFragment => !!item && '__typename' in item,
          ) || [];
      }

      // OTHER
      if (data.distinguishedStock) {
        this.distinguishedStock = data.distinguishedStock.filter(
          (item): item is DistinguishedStockIconFragment => !!item && '__typename' in item,
        );
      }
    }
  });

  get hideFeaturedSortOption() {
    return this.general.defaultSortOption !== 'recommended';
  }

  get isUsedCarPlatform() {
    return this.siteConfig?.siteGroup === 'usedCarPlatform';
  }

  get isPrimarySite() {
    return this.siteConfig?.siteGroup === 'mymoto';
  }

  get isBuyingSite() {
    return this.siteConfig?.siteGroup === 'buyingCentre';
  }

  get isFinanceSite() {
    return this.siteConfig?.siteGroup === 'finance';
  }

  get enableInternalContractSystem() {
    return false;
  }

  get enableInternalDepositSystem() {
    return !!this.integrations.enableInternalDepositSystem;
  }

  get showLocationTags() {
    return !this.isUsedCarPlatform || !!this.customization.optionalVehicleFilters?.stateRegion;
  }
}

const defaultMergeCustomizer: MergeWithCustomizer = (a, b) => (b === null || b === undefined ? a : undefined);
function globalSetMerge<T extends GlobalSets.GlobalSet<T>>(
  globalSet: T,
  data: GlobalSets.GlobalSetFragments,
  customizer: MergeWithCustomizer = defaultMergeCustomizer,
): T {
  return mergeWith(
    {},
    globalSet,
    omit(
      {
        ...data,
      },
      '__typename',
    ),
    customizer,
  );
}

/**
 * Customizer that sets values during merge with Global General Set data.
 * By default, will only merge values that are set (non-null & defined)
 */
function generalSetCustomizer(
  objValue: GlobalSets.GlobalSet<GeneralGlobalSet>[keyof GlobalSets.GlobalSet<GeneralGlobalSet>],
  srcValue: GeneralGlobalSetFragment[keyof GeneralGlobalSetFragment],
  key: keyof GlobalSets.General.Values,
  _obj: GlobalSets.GlobalSet<GeneralGlobalSet>,
  src: GeneralGlobalSetFragment,
) {
  switch (key) {
    case 'locationLinkField':
      if (src.locationLinkField && src.locationLinkField.type) {
        return src.locationLinkField;
      }
      break;
    case 'logo':
    case 'logoDark':
    case 'favicon':
      return src[`${key}`]?.[0] ?? null;
    // No default return
  }

  return srcValue === null || srcValue === undefined ? objValue : undefined;
}

function customizationSetCustomizer(
  objValue: GlobalSets.GlobalSet<CustomizationGlobalSet>[keyof GlobalSets.GlobalSet<CustomizationGlobalSet>],
  srcValue: CustomizationGlobalSetFragment[keyof CustomizationGlobalSetFragment],
  key: keyof GlobalSets.Customization.Values,
  _obj: GlobalSets.GlobalSet<CustomizationGlobalSet>,
  src: CustomizationGlobalSetFragment,
) {
  switch (key) {
    case 'actionButtonStyle':
      return src.actionButtonStyle && ['brand', 'action', 'foreground', 'action-light'].includes(src.actionButtonStyle)
        ? (src.actionButtonStyle as ButtonType)
        : 'button';
    case 'optionalVehicleFilters':
      return extractItems<OptionalVehicleFiltersFragment>(src.optionalVehicleFilters)?.[0] ?? null;
    case 'helpfulHintIcon':
      return src.helpfulHintIcon?.[0] ?? null;
    case 'sellMyCarFormFieldsVisibilityToggle':
      return (
        extractItems<SellMyCarFormFieldsVisibilityToggleFragment>(src.sellMyCarFormFieldsVisibilityToggle)?.[0] ?? null
      );
    case 'formsStepHelpfulHintBlocks':
      return extractItems<StepHelpfulHintBlocksFragment>(src.formsStepHelpfulHintBlocks?.nodes) ?? null;
    case 'vehicleHistoryReportContent':
      return extractItems<VehicleHistoryReportContentFragment>(src.vehicleHistoryReportContent)?.[0] ?? null;
    // No default return
  }

  return srcValue === null || srcValue === undefined ? objValue : undefined;
}
