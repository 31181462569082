import { deburr } from 'lodash';

import { DisclaimerBlockType } from '@/components/VehicleDetailsPage/constants';

export const slugify = (string: string) =>
  deburr(string)
    .trim()
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/([^a-zA-Z0-9x._-]+)/, '');

export const deslugify = (string: string) => string?.replace(/--/g, '_').replace(/-/g, ' ').replace(/_/g, '-') || '';

export const priceText = (price: number) => {
  if (price === 100) {
    return `$${price}K+`;
  }
  return `$${price}K`;
};

export const checkPhone = (string: string) => {
  const regex = /^([0-9]{8,32})$/;
  return regex.test(string);
};

export const checkEmail = (string: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(string);
};

export const checkPostcode = (string: string) => {
  const regex = /^([0-9]{4,})$/;
  return regex.test(string);
};

export const telPhone = (phone: string) => phone.replace(/[\D+]/g, '');

export const makeMap = (make: string) => {
  switch (make?.toUpperCase()) {
    case 'FORD PERFORMANCE VEHICLES':
      return 'FPV';
    case 'HOLDEN SPECIAL VEHICLES':
      return 'HSV';
    default:
      return make;
  }
};

export function capitalizeName(name: string | undefined) {
  if (!name) {
    return '';
  }

  return name
    .toLowerCase()
    .replace('-region', '')
    .replace('-suburb', '')
    .replace(/-/g, ' ')
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const toTitleCase = (str: string | undefined) => {
  if (!str) return;

  // Handle unqiue cases of Make/Model with switch
  switch (str.toLowerCase()) {
    case 'mercedes-benz':
      return 'Mercedes Benz';
    case 'mercedes-amg':
      return 'Mercedes AMG';
    case 'harley-davidson':
      return 'Harley Davidson';
    case 'suv':
    case 'bmw':
    case 'mg':
    case 'fpv':
    case 'hsv':
    case 'ldv':
    case 'mdc':
      return str.toUpperCase();
    default:
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
  }
};

export const getPrettyPriceFormat = (price: Maybe<number> | undefined, showDollar = true, showPOA = true) => {
  if (!price || price <= 0) {
    return showPOA ? 'POA' : showDollar ? '$0' : '0';
  }
  return (
    (!!showDollar ? '$' : '') +
    (price.toString().slice().length === 7 && price > 100000
      ? Math.abs(Number(price) / 1.0e6)
          .toFixed(2)
          .toLocaleString() + 'M'
      : price.toLocaleString())
  );
};

export const replaceStringByIndex = (source: string[], replace: string, idx: number) => {
  if (idx < 0 || source.length < idx + 1) {
    return source;
  }

  const newArray = [...source.slice(0, idx), replace, ...source.slice(idx + 1, source.length)];
  return newArray;
};

export const getDisclaimerBlock = <T>(
  data: Nullable<RewardsContentFragment> | Nullable<QualityAssuranceContentFragment>,
  blockType: DisclaimerBlockType[keyof DisclaimerBlockType],
): Nullable<T> => {
  const disclaimerBlock = data?.nodes?.filter(
    (n): n is RewardDisclaimerFragment | QualityAssuranceDisclaimerItemFragment =>
      !!(n && '__typename' in n) && n.__typename === blockType,
  );
  if (disclaimerBlock && disclaimerBlock.length > 0) {
    return disclaimerBlock[0] as Nullable<T>;
  }
  return null;
};

export const removeValuesFromObject = <T>(obj: T, keys: Array<keyof T>) => {
  const tempObj = { ...obj };
  keys.forEach((key) => !!key && delete tempObj[key]);
  return tempObj;
};

export const getArraySpread = <T>(array: T[], n: number): T[] => {
  if (n >= array.length) return array;
  const interval = array.length / n;
  const result: T[] = [];
  for (let i = 0; i < n; i++) {
    result.push(array[Math.floor(i * interval)]);
  }
  return result;
};

// Allows screen dimensions to be fetched on initial render
export const getIsTabletOrMobileOnLoad = (portraitOnly = false) => {
  const { type, angle } = typeof screen !== 'undefined' ? screen.orientation : { type: undefined, angle: 0 };
  const initialWidth = typeof document !== 'undefined' ? document.body.clientWidth : 1200; // default to desktop
  const landscapeMobileOrTablet = !portraitOnly && type === 'landscape-primary' && (angle === 90 || angle === 270);
  return initialWidth < 1024 || landscapeMobileOrTablet;
};

/**
 * Makes page fullscreen if the browser supports it
 * also support callback when fullscreen exit is occurs (e.g. back swipe or esc key)
 * Safari on IOS is the only browser that has minimal support
 * * Fullscreen call should only be used on interactive elements otherwise requestFullscreen will throw error
 */
export const fullScreenDocument = (document: Document, onExit?: () => void, conditional = true) => {
  if (!conditional) return;

  // checks whether device is already in fullscreen or supports fullscreen
  if (document.fullscreenEnabled && !document.fullscreenElement) {
    document.body.requestFullscreen({ navigationUI: 'hide' });

    // call event when fullscreen exits
    document.addEventListener('fullscreenchange', exitFullscrenHandler, false);
    document.addEventListener('mozfullscreenchange', exitFullscrenHandler, false);
    document.addEventListener('MSFullscreenChange', exitFullscrenHandler, false);
    document.addEventListener('webkitfullscreenchange', exitFullscrenHandler, false);
  }

  function exitFullscrenHandler() {
    // only null when exiting fullscreen
    if (!document.fullscreenElement) {
      onExit?.();

      // cleanup event listeners
      document.removeEventListener('fullscreenchange', exitFullscrenHandler, false);
      document.removeEventListener('mozfullscreenchange', exitFullscrenHandler, false);
      document.removeEventListener('MSFullscreenChange', exitFullscrenHandler, false);
      document.removeEventListener('webkitfullscreenchange', exitFullscrenHandler, false);
    }
  }
};

export const formatPhoneNumber = (phoneNumber: string) => {
  let formattedPhone = phoneNumber.replace(/[\D+]/g, '');
  formattedPhone = formattedPhone.replace(/^61/, '0');

  // \u00A0 is a non-breaking space and is recommended instead of spaces between phone number chunks
  if (formattedPhone.length === 10) {
    // 02,03,07,08 are valid landline area codes in Australia
    if (
      formattedPhone.startsWith('02') ||
      formattedPhone.startsWith('03') ||
      formattedPhone.startsWith('07') ||
      formattedPhone.startsWith('08')
    ) {
      // Landline formatting: (XX) XXXX XXXX
      return `(${formattedPhone.slice(0, 2)})\u00A0${formattedPhone.slice(2, 6)}\u00A0${formattedPhone.slice(6)}`;
    } else if (formattedPhone.startsWith('04')) {
      // Mobile formatting: XXXX XXX XXX
      return `${formattedPhone.slice(0, 4)}\u00A0${formattedPhone.slice(4, 7)}\u00A0${formattedPhone.slice(7)}`;
    }
  }

  return null;
};
